import { Container } from '../../../../container/Container';
import Image from 'next/image';

interface GarantiaBlindadaProps {
  data: any;
  temaColors?: any;
}

const GarantiaBlindada = ({ data, temaColors }: GarantiaBlindadaProps) => {
  const bgColor = temaColors.bgHeader2;
  const fontPrimary = temaColors['--font_primary'];

  return (
    <div style={{ backgroundColor: bgColor }} className="py-8 flex flex-wrap">
      <Container>
        <div className="flex flex-col md:flex-row justify-between items-center w-full gap-2">
          <div className="mb-8 md:mb-0 flex-grow">
            <h1 className="text-2xl sm:text-4xl uppercase font-bold mb-4 text-center sm:text-left">
              {data.titulo}
            </h1>
            <h2
              className={`text-[${fontPrimary}] font-bold text-base sm:text-xl mb-4 text-center`}>
              {data.subTitulo}
            </h2>
            <p className="text-justify">{data.conteudo}</p>
          </div>
          <div className="flex justify-center w-full">
            <Image
              src={data.imagem}
              alt="Garantia Blindada"
              width={340}
              height={340}
              unoptimized
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default GarantiaBlindada;
