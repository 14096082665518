import Image from 'next/image';
import { useEffect, useState } from 'react';

const ProductCard = ({ data }: any) => {
  const [queryParams, setQueryParams] = useState<string>('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const searchParams = window.location.search;
      setQueryParams(
        searchParams.startsWith('?') ? searchParams.slice(1) : searchParams
      );
    }
  }, []);

  const getCheckoutUrlWithParams = (checkoutUrl: string) => {
    const url = new URL(checkoutUrl);
    if (queryParams) {
      const existingParams = url.searchParams.toString();
      url.search = existingParams
        ? `${existingParams}&${queryParams}`
        : `?${queryParams}`;
    }
    return url.toString();
  };

  const handleProductClick = (link: string) => {
    window.location.href = getCheckoutUrlWithParams(link);
  };

  return (
    <section className="flex flex-col md:flex-row items-center justify-center gap-6 md:gap-4 my-14 shadow-sm">
      {data.map((produto: any, index: number) => (
        <div
          key={index}
          onClick={() => handleProductClick(produto.link)}
          className="relative flex flex-col items-center gap-2 p-3 w-full md:w-96 shadow-md shadow-gray-500 rounded-md font-bold uppercase text-center bg-[var(--product-background)] z-10 cursor-pointer">
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-full h-1/3 bg-[var(--primary)] rounded-bl-[50%] z-[-1]"></div>
          <div className="flex flex-col items-center justify-center text-white drop-shadow-[0_1px_2px_black] gap-2">
            <h3 className="text-xl">{produto.title}</h3>
            <h4 className="text-base">{produto.tempoTratamento}</h4>
            <Image
              src={produto.imagem}
              width={300}
              height={300}
              alt={`kit-${index + 1}`}
              className="transition-transform duration-500 transform hover:scale-110"
              unoptimized
            />
          </div>
          <span className="font-normal text-lg">USTED PAGA</span>
          <span className="text-red-500 font-normal text-sm font-style-italic line-through">
            {produto.semDesconto}
          </span>
          <span className="text-lg uppercase">{produto.preco}</span>
          <p className="text-lg">{produto.frete}</p>
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleProductClick(produto.link);
            }}
            className="bg-[var(--primary)] capitalize text-white w-full md:w-3/4 rounded-sm p-2 filter hover:brightness-75">
            <span className="drop-shadow-[0_0_1px_black]">
              Hacer pedido
              <br />
              (pagar al recibir)
            </span>
          </button>
        </div>
      ))}
    </section>
  );
};

export default ProductCard;
