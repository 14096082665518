import { Container } from '@/app/container/Container';
import Image from 'next/image';
import React from 'react';

const Seguranca = () => {
  return (
    <section className="bg-white py-12" id="sec-ce4f">
      <Container>
        <div className="flex flex-col items-start justify-center md:flex-row md:space-x-8">
          <div className="flex items-center justify-center space-x-4 mb-4 md:mb-0">
            <Image
              src="/pv-brasil/7339419-139a2e0d.png"
              alt="Site Blindado"
              width={40}
              height={40}
            />
            <p className="text-sm font-bold">
              SITE BLINDADO. SEUS DADOS FICARÃO TOTALMENTE SEGUROS!
            </p>
          </div>
          <div className="flex items-center justify-center space-x-4 mb-4 md:mb-0">
            <Image
              src="/pv-brasil/679720-ae4e3ed8.png"
              alt="Entrega via Correios"
              width={40}
              height={40}
            />
            <p className="text-sm font-bold">
              ENTREGA PARA TODO BRASIL VIA CORREIOS
            </p>
          </div>
          <div className="flex items-center justify-center space-x-4 mb-4 md:mb-0">
            <Image
              src="/pv-brasil/271035-f2cb3991.png"
              alt="Pagamento parcelado"
              width={40}
              height={40}
            />
            <p className="text-sm font-bold">
              PAGUE NO CARTÃO DE CRÉDITO PARCELADO EM ATÉ 12X
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Seguranca;
