'use client';
import { useEffect } from 'react';

// Define a estrutura das props que o componente espera receber
interface BackIntentScriptProps {
  redirectUrl: string; // URL para redirecionamento (ex: "http://localhost:3000/backredirect1/facebook")
  tipo: string; // Tipo da página pra saber se é VSL ou PV e definir o tempo de espera (ex: se for uma vsl, 15 minutos, se for pv, 500ms)
}

// !!Usei exemplo com localhost na documentação mas funciona com domínio real do template

/**
 * Componente BackIntentScript
 *
 * Este componente gerencia o redirecionamento quando o usuário tenta voltar na página.
 * Ele lida com diferentes cenários baseados na URL atual, no número do backredirect e no tipo de página.
 *
 * Exemplo de fluxo:
 * 1. Usuário está em: http://localhost:3000/vsl-br
 * 2. Ao tentar voltar após 15 minutos (se for VSL), é redirecionado para: http://localhost:3000/backredirect1/facebook (valor do redirectUrl)
 * (se for PV, após 500ms vai direto para o backredirect)
 * 3. Se tentar voltar novamente, vai para: http://localhost:3000/backredirect2/facebook
 * 4. Se tentar voltar mais uma vez, permanece em loop no backredirect2: http://localhost:3000/backredirect2/facebook
 */
const BackIntentScript = ({ redirectUrl, tipo }: BackIntentScriptProps) => {
  // Função que trata o redirecionamento
  useEffect(() => {
    const handleBackIntent = () => {
      console.log(`Tipo recebido: ${tipo}`); // Log para verificar o tipo recebido

      // Verifica se o tipo contém "vsl" para aplicar o tempo de 15 minutos
      const isVSL = tipo?.toLowerCase().includes('vsl');
      const tempo = isVSL ? 15 * 60 * 1000 : 500; // 15 minutos para VSL, 500ms para outros casos

      console.log(
        `Tipo: ${tipo}, É VSL: ${isVSL}, Tempo de espera: ${tempo}ms`
      ); // Log para debug

      const urlParams = new URLSearchParams(window.location.search);
      const queryString = urlParams.toString();

      /**
       * Obtém o número atual do backredirect da URL
       * @returns {number} Número do backredirect ou 0 se não estiver em uma página de backredirect
       *
       * Exemplos:
       * - Para URL "http://localhost:3000/vsl-br" retorna 0
       * - Para URL "http://localhost:3000/backredirect1/facebook" retorna 1
       * - Para URL "http://localhost:3000/backredirect2/youtube" retorna 2
       */

      // Função que extrai o número do backredirect da URL
      const getCurrentBackredirect = () => {
        const match = window.location.pathname.match(/backredirect(\d+)/);
        console.log(`Match: ${match}`); // Log para debug
        return match ? parseInt(match[1]) : 0;
      };

      /**
       * Extrai o tipo de checkout da URL
       * @param {string} url - URL para extrair o tipo de checkout
       * @returns {string} Tipo de checkout ou string vazia se não encontrado
       *
       * Exemplos:
       * - Para URL "http://localhost:3000/backredirect1/facebook" retorna "facebook"
       * - Para URL "http://localhost:3000/backredirect2/youtube" retorna "youtube"
       * É dinâmico, então retorna o valor que estiver definido no backredirect (ex: facebook, youtube, etc)
       */

      // Função que extrai o tipo de checkout da URL
      const getCheckoutType = (url: string) => {
        const match = url.match(/backredirect\d+\/([^/]+)/);
        console.log(`Match: ${match}`); // Log para debug
        return match ? match[1] : '';
      };

      /**
       * Determina a próxima URL baseada no backredirect atual
       * @returns {string} URL para o próximo redirecionamento
       *
       * Exemplos de comportamento:
       * 1. Se estiver em "http://localhost:3000/vsl-br":
       *    - Retorna "http://localhost:3000/backredirect1/facebook" (assumindo que este é o redirectUrl)
       *
       * 2. Se estiver em "http://localhost:3000/backredirect1/facebook":
       *    - Retorna "http://localhost:3000/backredirect2/facebook"
       *
       * 3. Se estiver em "http://localhost:3000/backredirect2/facebook" (ou qualquer backredirect2):
       *    - Retorna a mesma URL, criando um loop
       */

      // Função que determina a próxima URL baseada no backredirect atual
      const getNextUrl = () => {
        const currentBackredirect = getCurrentBackredirect();
        let checkoutType = '';
        console.log(
          `Url atual: "${currentBackredirect}" nesse caso é a ${
            currentBackredirect === 0
              ? 'página inicial do produto'
              : currentBackredirect === 1
                ? 'página do backredirect 1'
                : 'página do backredirect 2'
          }`
        ); // Log para debug
        console.log(`URL completa: "${window.location.href}"`); // Log para debug

        if (currentBackredirect === 0) {
          // Se estamos na página inicial do produto, use o redirectUrl fornecido ou a URL atual
          return redirectUrl || window.location.href;
        } else if (currentBackredirect === 1) {
          // Se estamos em backredirect1, pegue o tipo de checkout da URL atual
          checkoutType = getCheckoutType(window.location.pathname);
          const baseUrl = window.location.origin;
          console.log(`Base URL: "${baseUrl}"`); // Log para debug
          return `${baseUrl}/backredirect2/${checkoutType}${queryString ? '?' + queryString : ''}`;
        } else {
          // Se estamos em backredirect2 ou qualquer outro, fique no mesmo backredirect
          return window.location.href;
        }
      };

      const nextUrl = getNextUrl();
      console.log(`Próxima URL: "${nextUrl}"`); // Log para debug
      console.log(`Tempo: ${tempo}ms`); // Log para debug
      try {
        setTimeout(() => {
          console.log(`Configurando redirecionamento após ${tempo}ms`); // Log para debug
          // Adiciona um novo estado ao histórico para prevenir o comportamento padrão de voltar
          history.pushState(null, '', window.location.href);
          // Define o comportamento quando o usuário tenta voltar
          window.onpopstate = () => {
            console.log(`Voltando para: ${nextUrl}`); // Log para debug
            history.pushState(null, '', window.location.href);
            window.location.replace(nextUrl);
          };
        }, tempo);
      } catch (error) {
        console.error(error);
      }
    };

    // Executa a lógica de redirecionamento
    handleBackIntent();

    // Limpa o evento onpopstate quando o componente é desmontado
    return () => {
      window.onpopstate = null;
    };
  }, [redirectUrl, tipo]); // Executa o efeito quando redirectUrl ou tipo mudam

  return null; // Este componente não renderiza nada visualmente
};

export default BackIntentScript;
