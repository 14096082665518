import { Container } from '@/app/container/Container';
import React from 'react';

interface FAQProps {
  data: {
    faq: {
      descricao: string;
      perguntas: {
        pergunta: string;
        resposta: string;
      }[];
    };
  };
}

const FAQ = ({ data }: FAQProps) => {
  return (
    <section id="faq" className="bg-black py-12" aria-labelledby="faq-titulo">
      <Container>
        <div className="w-full">
          <div className="text-white">
            <h2
              id="faq-titulo"
              className="text-3xl md:text-5xl font-bold text-center mb-8">
              Perguntas Frequentes
            </h2>
            <p className="text-center mb-10">{data.faq.descricao}</p>
          </div>
          <div className="flex flex-col gap-4 p-2 rounded-lg shadow-lg">
            {data.faq.perguntas.map((item, index) => (
              <div
                key={index}
                className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box">
                <input type="checkbox" className="peer" />
                <div className="collapse-title text-lg font-medium bg-gray-200 text-black">
                  {item.pergunta}
                </div>
                <div className="collapse-content">
                  <p>{item.resposta}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="text-center mt-10">
            <a
              href="#sec-7b55"
              className="inline-block crm-primary-bg hover:brightness-110 text-white font-bold py-3 px-6 rounded-full transition-colors duration-300">
              Sim, quero comprar com desconto!
            </a>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default FAQ;
