import { Container } from '@/app/container/Container'; // Importa o componente Container
import Image from 'next/image'; // Importa o componente Image do Next.js para otimização de imagens
import Link from 'next/link'; // Importa o componente Link do Next.js para navegação interna

// Define o componente Formula, que recebe data (que vem da API do CRM) como propriedade
const Formula = ({ data }: any) => {
  return (
    // Seção principal do componente, com background branco e padding responsivo
    <section className="bg-white p-4 md:p-8">
      {/* Utiliza o componente Container para centralizar e conter o layout */}
      <Container>
        {/* Flex container para alinhar os elementos filhos */}
        <div className="flex flex-wrap justify-between items-center">
          {/* Define a largura responsiva para a coluna esquerda */}
          <div className="w-full md:w-1/2 p-4">
            {/* Container para o conteúdo da coluna esquerda, centralizado */}
            <div className="flex flex-col items-center text-center">
              <h2 className="text-2xl md:text-3xl font-bold crm-primary-text">
                {/* Exibe o título dinâmico passado
                via props */}
                {data.comoFunciona.titulo}
              </h2>
              <p className="mt-4 text-sm md:text-base">
                {/* Exibe a descrição dinâmica
                passada via props */}
                {data.comoFunciona.descricao}
              </p>
              <Link
                href="#sec-7b55" // Link para a seção específica
                className="inline-block mt-4 crm-primary-bg text-white font-bold py-2 px-6 rounded-full transition-colors duration-300 hover:brightness-90 uppercase">
                Sim, quero comprar com desconto!
                {/* Botão com estilo
                personalizado e ação de hover */}
              </Link>
            </div>
          </div>
          <div
            className="w-full md:w-1/2 p-4"
            // Define a largura responsiva para a coluna direita
          >
            <div
              className="flex justify-center items-center"
              // Container para centralizar a imagem dentro da coluna
            >
              <Image
                alt="Descrição do Produto" // Texto alternativo para a imagem, importante para acessibilidade
                width={300} // Largura fixa da imagem
                height={300} // Altura fixa da imagem
                className="transform hover:scale-105 transition-transform duration-300"
                // className acima contém um efeito de hover para a imagem
                src={data.comoFunciona.foto} // Fonte da imagem dinâmica passada via props
                unoptimized
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Formula;
// Exporta o componente Formula para ser utilizado em outras partes do projeto
