import Link from 'next/link';
import { Container } from '@/app/container/Container';
import Image from 'next/image';

interface FooterProps {
  data?: any;
}

export default function Footer({ data }: FooterProps) {
  return (
    <footer className="bg-white pt-12">
      <Container>
        <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-8 text-center text-xs font-bold">
          <div className="flex flex-col items-center gap-2 bg-black p-2 rounded-xl">
            <svg
              className="w-5 h-5"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.7092 3H8.29411C7.0289 3 6.00311 4.02579 6.00311 5.291V14.7061C6.00311 15.9713 7.0289 16.9971 8.29411 16.9971H17.7092C18.9744 16.9971 20.0002 15.9713 20.0002 14.7061V5.291C20.0002 4.02579 18.9744 3 17.7092 3ZM8.00311 14.7061V5.291C8.00311 5.13041 8.13353 5 8.29411 5H11.6527C11.8143 5 11.9447 5.13041 11.9447 5.29197V14.7051C11.9447 14.8667 11.8143 14.9971 11.6527 14.9971H8.29411C8.13353 14.9971 8.00311 14.8667 8.00311 14.7061ZM18.0002 14.7061C18.0002 14.8667 17.8698 14.9971 17.7092 14.9971H14.1608C13.9992 14.9971 13.8688 14.8667 13.8688 14.7051V5.29197C13.8688 5.13041 13.9992 5 14.1608 5H17.7092C17.8698 5 18.0002 5.13041 18.0002 5.291V14.7061ZM3.01703 4.98944H0.984915C0.440876 4.98944 0 5.43129 0 5.97533C0 6.51937 0.440876 6.96024 0.984915 6.96024H3.01703C3.56107 6.96024 4.00195 6.51937 4.00195 5.97533C4.00195 5.43129 3.56107 4.98944 3.01703 4.98944ZM0.984915 9.01349H3.01703C3.56107 9.01349 4.00195 9.45436 4.00195 9.9984C4.00195 10.5424 3.56107 10.9833 3.01703 10.9833H0.984915C0.440876 10.9833 0 10.5424 0 9.9984C0 9.45436 0.440876 9.01349 0.984915 9.01349ZM3.01703 13.0098H0.984915C0.440876 13.0098 0 13.4506 0 13.9947C0 14.5387 0.440876 14.9796 0.984915 14.9796H3.01703C3.56107 14.9796 4.00195 14.5387 4.00195 13.9947C4.00195 13.4506 3.56107 13.0098 3.01703 13.0098ZM16.8604 7.46961C17.1509 7.93967 17.0053 8.5562 16.5353 8.84668C16.0652 9.13716 15.4487 8.99158 15.1582 8.52152C14.8677 8.05147 15.0133 7.43493 15.4834 7.14445C15.9534 6.85398 16.57 6.99956 16.8604 7.46961Z"
                fill="#fff"
              />
            </svg>
            <p className="text-white">ENTREGA EXPRESSA</p>
          </div>

          <div className="flex flex-col items-center gap-2 bg-black p-2 rounded-xl">
            <svg
              className="w-5 h-5"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18.0014 3H1.99864C0.894767 3 0 3.89477 0 4.99864V15.0268C0 16.1307 0.894767 17.0255 1.99864 17.0255H18.0014C19.1052 17.0255 20 16.1307 20 15.0268V4.99864C20 3.89477 19.1052 3 18.0014 3ZM18.0014 5.29041V6.68897C18.0014 6.85042 17.871 6.98074 17.7096 6.98074H2.29041C2.12896 6.98074 1.99864 6.85042 1.99864 6.68897V5.29041C1.99864 5.12896 2.12896 4.99864 2.29041 4.99864H17.7096C17.871 4.99864 18.0014 5.12896 18.0014 5.29041ZM1.99864 14.7351V9.31006C1.99864 9.14861 2.12896 9.01828 2.29041 9.01828H17.7096C17.871 9.01828 18.0014 9.14861 18.0014 9.31006V14.7351C18.0014 14.8965 17.871 15.0268 17.7096 15.0268H2.29041C2.12896 15.0268 1.99864 14.8965 1.99864 14.7351Z"
                fill="#FFF"
              />
            </svg>
            <p className="text-white">12X SEM JUROS</p>
          </div>

          <div className="flex flex-col items-center gap-2 bg-black p-2 rounded-xl">
            <svg
              className="w-5 h-5"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.3268 2.99679L10.008 2.03291L4.71736 2.99192C4.3026 3.06689 4.00175 3.4281 4.00175 3.8487V12.0095C4.00175 12.6512 4.28313 13.3405 4.83712 14.059C5.3765 14.759 6.15539 15.4669 7.15043 16.162C8.23309 16.9185 9.31575 17.4813 10.007 17.8094C10.7129 17.4725 11.8277 16.8932 12.921 16.1231C13.9015 15.4318 14.6667 14.7298 15.1974 14.0356C15.7406 13.3259 16.0152 12.6434 16.0152 12.0095V3.82047C16.0152 3.41544 15.7251 3.06884 15.3268 2.99679ZM3.93847 1.10019L10.008 0L15.8438 1.05832C17.1017 1.28615 18.016 2.38049 18.016 3.65885V12.0105C18.016 16.8046 10.008 20 10.008 20C10.008 20 2 16.8046 2 12.0105V3.42128C2 2.28118 2.81589 1.30367 3.93847 1.10019ZM11.441 7.05385C11.808 6.6868 12.4039 6.6868 12.7709 7.05385C13.138 7.42091 13.138 8.01676 12.7719 8.38284L10.1013 11.0535C10.0961 11.059 10.0912 11.0647 10.0862 11.0703C10.079 11.0786 10.0718 11.0868 10.0643 11.0944C9.7021 11.4565 9.11598 11.4565 8.75379 11.0944L7.43552 9.77608C7.07333 9.4139 7.07333 8.82778 7.43552 8.4656C7.7977 8.10341 8.38382 8.10341 8.746 8.4656L9.38762 9.10721L11.441 7.05385Z"
                fill="#FFF"></path>
            </svg>
            <p className="text-white">SITE SEGURO</p>
          </div>
        </div>

        <div className="w-full mt-8 grid grid-cols-1 md:grid-cols-2 text-center gap-8">
          <div>
            <p>
              Você pode comprar agora pelo telefone ou tirar suas dúvidas com um
              de nossos atendentes
            </p>
          </div>

          <div className="flex justify-center items-center text-center">
            <Link
              href={data?.urlWhatsapp}
              className="inline-block py-2 px-4 rounded-full">
              <p className="text-black flex items-center justify-center">
                <svg
                  className="w-4 h-4 mr-2"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.3364 8.50447L10.1104 7.27797C9.97987 7.14719 9.82482 7.04343 9.65413 6.97263C9.48344 6.90184 9.30046 6.8654 9.11567 6.8654C8.93088 6.8654 8.7479 6.90184 8.57721 6.97263C8.40652 7.04343 8.25147 7.14719 8.12092 7.27797L7.87092 7.52797C6.63879 6.4956 5.50066 5.35596 4.46992 4.12247L4.71992 3.87247C4.98317 3.60845 5.131 3.25081 5.131 2.87797C5.131 2.50513 4.98317 2.1475 4.71992 1.88347L3.49142 0.65847C3.22322 0.402253 2.86658 0.259277 2.49567 0.259277C2.12476 0.259277 1.76812 0.402253 1.49992 0.65847L0.82992 1.33147C0.507962 1.65575 0.307381 2.08092 0.261858 2.53561C0.216335 2.9903 0.328642 3.4468 0.57992 3.82847C2.57747 6.83838 5.1569 9.41833 8.16642 11.4165C8.5494 11.6654 9.00584 11.7764 9.46037 11.7309C9.91489 11.6855 10.3403 11.4864 10.6664 11.1665L11.3389 10.4935C11.4697 10.363 11.5734 10.208 11.6442 10.0374C11.715 9.86682 11.7514 9.68393 11.7514 9.49922C11.7514 9.31451 11.715 9.13162 11.6442 8.96101C11.5734 8.7904 11.4697 8.63543 11.3389 8.50497L11.3364 8.50447Z"
                    fill="#121212"></path>
                </svg>
                <strong className="hover:underline">Televendas</strong>
              </p>
            </Link>
          </div>
        </div>

        <div className="w-full mt-8 grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="flex items-center justify-center text-xs text-center">
            <Link href="https://cademeupedido.log.br/?t=U2l4UHJvZHV0b3M">
              <Image
                width={300}
                height={300}
                src="/backredirect/rastreae.png"
                alt="Rastreie seu pedido"
              />
            </Link>
          </div>

          <div className="flex flex-col items-center justify-center text-xs text-center">
            <h4 className="font-bold mb-2">Formas de Pagamento</h4>
            <Image
              width={300}
              height={300}
              src="/backredirect/pagamento-icon.png"
              alt="Formas de pagamento"
            />
          </div>
        </div>
      </Container>

      <hr className="w-full my-8 border-gray-200" />

      <Container>
        <div className="w-full grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="text-xs text-center">
            <Link href={data?.urlWhatsapp} target="_blank">
              <p className="flex items-center justify-center hover:text-gray-800 focus:text-gray-800 focus:outline-none transition-colors duration-200">
                <svg
                  className="w-5 h-5 mr-2"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.07196 13.335C2.07196 14.1688 2.75155 14.8474 3.58736 14.8474C3.99551 14.8474 4.37924 14.6844 4.66729 14.3905L7.53211 11.4475H8.41345C8.07358 12.2043 7.88452 13.0435 7.88452 13.9268C7.88452 17.273 10.597 19.9845 13.9422 19.9845C17.2874 19.9845 19.9999 17.272 19.9999 13.9268C19.9999 11.3335 18.3703 9.12038 16.0787 8.25657V2.35122C16.0787 1.05454 15.0242 0 13.7275 0H2.35122C1.05454 0 0 1.05454 0 2.35122V9.09634C0 10.2983 0.906114 11.2923 2.07196 11.4309V13.335ZM7.3456 9.44099H9.87105C10.9465 8.46433 12.3747 7.86911 13.9422 7.86911C13.9853 7.86911 14.0283 7.86956 14.0712 7.87046V2.35122C14.0712 2.16082 13.9169 2.00655 13.7265 2.00655H2.35122C2.16082 2.00655 2.00655 2.16082 2.00655 2.35122V9.09634C2.00655 9.28674 2.16082 9.44099 2.35122 9.44099H2.83357C3.51999 9.44099 4.0785 9.9995 4.0785 10.6859V12.1174L6.22272 9.91456C6.51564 9.61383 6.92476 9.44099 7.3456 9.44099ZM13.9422 9.87566C16.1763 9.87566 17.9934 11.6928 17.9934 13.9268C17.9934 16.1609 16.1763 17.978 13.9422 17.978C11.7082 17.978 9.89107 16.1609 9.89107 13.9268C9.89107 11.6928 11.7082 9.87566 13.9422 9.87566ZM13.9422 13.2704C13.3886 13.2704 12.9395 13.7195 12.9395 14.2732V16.2817C12.9395 16.8353 13.3886 17.2845 13.9422 17.2845C14.4959 17.2845 14.945 16.8353 14.945 16.2817V14.2732C14.946 13.7195 14.4968 13.2704 13.9422 13.2704ZM12.9395 11.5716C12.9395 11.018 13.3886 10.5688 13.9422 10.5688C14.4968 10.5688 14.946 11.018 14.945 11.5716C14.945 12.1253 14.4959 12.5744 13.9422 12.5744C13.3886 12.5744 12.9395 12.1253 12.9395 11.5716Z"
                    fill="#121212"></path>
                </svg>
                <strong>Compre via Whatsapp</strong>
              </p>
            </Link>
          </div>

          <div className="text-xs text-center">
            <Link href="/">
              <p className="hover:text-gray-800 focus:text-gray-800 focus:outline-none transition-colors duration-200">
                <strong>Sobre Nós</strong>
              </p>
            </Link>
          </div>

          <div className="text-xs text-center">
            <Link href="/">
              <p className="hover:text-gray-800 focus:text-gray-800 focus:outline-none transition-colors duration-200">
                <strong>Perguntas Frequentes</strong>
              </p>
            </Link>
          </div>

          <div className="text-xs text-center">
            <Link href="/">
              <p className="hover:text-gray-800 focus:text-gray-800 focus:outline-none transition-colors duration-200">
                <strong>Politica de privacidade</strong>
              </p>
            </Link>
          </div>

          <div className="text-xs text-center">
            <Link href="/">
              <p className="hover:text-gray-800 focus:text-gray-800 focus:outline-none transition-colors duration-200">
                <strong>Termos e condições</strong>
              </p>
            </Link>
          </div>
        </div>
      </Container>

      <hr className="w-full my-8 border-gray-200" />
    </footer>
  );
}
