import Image from 'next/image';
import { Container } from '@/app/container/Container';
import Link from 'next/link';

const Header = ({ data }: any) => {
  return (
    <header className="crm-primary-bg py-4 md:py-6" id="sec-b4a2">
      <Container>
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center">
            <Link href="#" passHref className="w-24 md:w-32">
              <Image
                alt="Logo"
                width={100}
                height={100}
                src={data.foto}
                className="w-full h-full"
                unoptimized
              />
            </Link>
          </div>
          <nav className="flex items-center gap-4 text-sm md:text-base uppercase">
            <Link
              href="#sec-7b55"
              className="hover:text-gray-3000 focus:text-gray-3000 focus:outline-none transition-colors duration-200">
              <button className="bg-black/50 text-white px-4 py-2 rounded-md hover:bg-black/70 transition-colors duration-200">
                ¡Probarlo Ahora!
              </button>
            </Link>
          </nav>
        </div>
      </Container>
    </header>
  );
};

export default Header;
