import { FC } from 'react';
import { Container } from '@/app/container/Container';
import Image from 'next/image';

interface FooterProps {
  data: {
    copyright: string;
  };
}

const Footer: FC<FooterProps> = ({ data }) => {
  return (
    <footer className="bg-black/90 text-white py-8" id="sec-1c68">
      <Container>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 text-center">
          <div>
            <h3 className="text-xl mb-4">MÉTODOS DE PAGO</h3>
            <div className="flex items-center justify-center">
              <Image
                src="/formasPagamento.webp"
                alt="Métodos de Pago"
                width={200}
                height={50}
                className="object-contain"
              />
            </div>
          </div>
          <div>
            <h3 className="text-xl mb-4">SITIO WEB 100% SEGURO</h3>
            <div className="flex items-center justify-center">
              <Image
                src="/siteSeguro.webp"
                alt="Site Seguro"
                width={200}
                height={50}
                className="object-contain"
              />
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-700 pt-4 text-center">
          <p className="mt-4 text-gray-400 text-sm md:text-base">
            {data.copyright}
          </p>
          <div className="w-full grid grid-cols-1 sm:grid-cols-3 gap-2 text-center text-sm md:text-base crm-primary-text brightness-200 mt-4">
            <a href="#" className="hover:underline">
              Política de privacidad
            </a>
            <a href="#" className="hover:underline">
              Política de cambio y reembolso
            </a>
            <a href="#" className="hover:underline">
              Terminos de uso
            </a>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
