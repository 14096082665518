import Image from 'next/image';
import { Container } from '../../../../container/Container';

interface EntregaSeguraProps {
  data: {
    tituloEntrega: string;
    textoEntrega: string;
    tituloSeguro: string;
    textoSeguro: string;
  };
}

const EntregaSegura = ({ data }: EntregaSeguraProps) => {
  return (
    <Container>
      <ul className="grid grid-cols-1 sm:grid-cols-2 gap-6 my-8">
        <li className="flex flex-col items-center">
          <div className="flex flex-col items-center justify-center gap-2 bg-black rounded-lg py-4 px-6 text-center text-white uppercase w-full">
            <Image
              src="/backredirect/entrega.svg"
              alt="Entrega Segura"
              width={32}
              height={32}
              className="w-8 h-8"
            />
            <h2 className="text-sm font-bold">{data.tituloEntrega}</h2>
          </div>
          <p className="mt-4 text-center font-bold">{data.textoEntrega}</p>
        </li>
        <li className="flex flex-col items-center">
          <div className="flex flex-col items-center justify-center gap-2 bg-black rounded-lg py-4 px-6 text-center text-white uppercase w-full">
            <Image
              src="/backredirect/seguro.svg"
              alt="Entrega Segura"
              width={32}
              height={32}
              className="w-8 h-8"
            />
            <h2 className="text-sm font-bold">{data.tituloSeguro}</h2>
          </div>
          <p className="mt-4 text-center font-bold">{data.textoSeguro}</p>
        </li>
      </ul>
    </Container>
  );
};

export default EntregaSegura;
