import Image from 'next/image';
import React from 'react';

interface ProdutoProps {
  produto: {
    id: string;
    title: string;
    tempoTratamento: string;
    imagem: string;
    semDesconto: string;
    preco: number;
    link: string;
  };
}

const ProdutosData = ({ produto }: ProdutoProps) => {
  const handleProductClick = (link: string) => {
    window.location.href = link; // Redireciona o usuário para o link do produto
  };

  return (
    <div className="crm-primary-bg text-white rounded-3xl shadow-lg p-6 flex flex-col items-center justify-center text-center">
      <h1 className="w-full block bg-black text-white font-bold py-2 px-4 rounded-full mb-4">
        {produto.title}
      </h1>
      <h2 className="font-semibold mb-4">{produto.tempoTratamento}</h2>
      <div className="object-contain flex items-center justify-center mb-4">
        <Image
          className="w-full h-full hover:scale-105 transition-all duration-300"
          width={300}
          height={300}
          src={produto.imagem}
          alt={produto.title}
          unoptimized
        />
      </div>
      <div className="flex flex-col items-center justify-center text-center gap-2">
        <p>
          DESDE <span className="font-semibold">${produto.semDesconto}</span>{' '}
          POR
        </p>
        <p className="text-yellow-300 text-5xl font-bold drop-shadow-2xl">
          ${produto.preco.toLocaleString('es-CO')}
        </p>
        <span className="text-sm font-semibold mb-4">
          Descuento de $
          <span className="font-bold">
            {(
              parseInt(produto.semDesconto.replace(/[^0-9]/g, '')) -
              produto.preco
            ).toLocaleString('es-CO')}
          </span>
        </span>
      </div>
      <p className="text-sm font-bold">COMPROBAR ENVÍO</p>
      <button
        className="w-full bg-black text-white font-bold p-4 rounded-full mt-4 hover:bg-black/80 transition-all duration-300"
        onClick={() => handleProductClick(produto.link)}
      >
        ¡Comprar ahora!
      </button>
    </div>
  );
};

export default ProdutosData;