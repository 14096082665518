import { Container } from '@/app/container/Container';
import Image from 'next/image';
import Link from 'next/link';

interface HeroProps {
  data: {
    infoBanner: {
      titulo: string;
      items: string[];
      imagem: string;
      botao: string;
    };
  };
}

const Hero = ({ data }: HeroProps) => {
  return (
    <section className="crm-primary-bg text-white py-10" id="sec-e6af">
      <Container>
        <div className="w-full flex flex-col md:flex-row items-center justify-between">
          {/* Left Column for Text and Benefits */}
          <div className="flex flex-col sm:items-start items-center justify-center w-full md:w-1/2">
            <h2 className="text-3xl sm:px-6 font-bold mb-4 text-center drop-shadow-2xl">
              {data.infoBanner.titulo}
            </h2>
            <ul className="text-lg space-y-2">
              {data.infoBanner.items.map((item, index) => (
                <li key={index} className="flex items-center space-x-2">
                  <svg
                    className="bg-green-500 w-4 h-4 flex-shrink-0 rounded-full"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    style={{ minHeight: '16px', minWidth: '16px' }}>
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>{item}</span>
                </li>
              ))}
            </ul>
            <Link
              href="#sec-7b55"
              className="w-full mt-4 bg-black/45 text-white text-center font-bold p-2 rounded-3xl transition-colors duration-300 hover:bg-black/60"
              dangerouslySetInnerHTML={{ __html: data.infoBanner.botao }}
            />
          </div>

          {/* Right Column for Images */}
          <div className="w-full md:w-1/2 flex justify-center md:justify-end mt-4 md:mt-0">
            <Image
              src={data.infoBanner.imagem}
              alt="Unidade do produto"
              width={300}
              height={300}
              className="transform hover:scale-105 transition-transform duration-300"
              unoptimized
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Hero;
