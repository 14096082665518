import Header from './header';
import Tabelas from './tabelas';
import Seguranca from './seguranca';
import Garantia from './garantia';
import Footer from './footer';
import Rodape from './rodape';
import { GoogleAnalytics } from '@next/third-parties/google';
import BackIntentScript from '../BackIntentScript';

interface BackredirectTemplateProps {
  data: any;
  backredirect: string;
  tipo: string;
}

export default function BackredirectTemplate({
  data,
  backredirect,
  tipo
}: BackredirectTemplateProps) {
  const tabelas = data.tabelas[backredirect];
  const { tema } = data;

  return (
    <div id="backredirect-template" style={tema}>
      <GoogleAnalytics gaId={data?.gtagId} />
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "${data?.clarityId}");
          `
        }}
      />
      <Header data={data?.header} />
      <Tabelas data={tabelas} tipo={tipo} />
      <Seguranca />
      <Garantia data={data?.garantia} />
      <Footer data={data?.footer} />
      <Rodape data={data?.rodape} />
      <BackIntentScript redirectUrl={data?.redirectUrl} tipo={data?.tipo} />
    </div>
  );
}
