const AvisoDesconto = ({ data }: any) => {
  return (
    <div className="flex justify-center text-center font-bold bg-desconto p-2">
      <p className="crm-primary-text animate-scale w-4/5 text-sm sm:text-base lg:text-lg xl:text-xl">
        {data?.textoDesconto ||
          'PARABÉNS! VOCÊ ACABA DE RECEBER 50% DE DESCONTO EM TODO SITE!'}
      </p>
    </div>
  );
};

export default AvisoDesconto;
