import { Container } from '@/app/container/Container';
import { FC } from 'react';

interface AtendimentoComercialProps {
  data: {
    contatoWhats: string;
    emailContato: string;
    descricaoPrazo: string;
  };
}

const AtendimentoComercial: FC<AtendimentoComercialProps> = ({ data }) => {
  return (
    <section className="bg-black/65 text-white py-12" id="sec-8e9a">
      <Container>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
            <div>
              <h2 className="text-3xl font-bold mb-6">
                Atendimento e Comercial
              </h2>
              <p className="mb-6">
                Time Comercial (VENDAS) - Segunda à Domingo: 9h às 21h | Horário
                de Brasília
              </p>
              <a
                href={data.contatoWhats}
                className="inline-block bg-green-600 text-white font-bold py-3 px-6 rounded-full mb-6 transition duration-300 hover:bg-green-700">
                Comprar via Whatsapp
              </a>
              <p className="mb-6">
                Serviço de Atendimento ao Cliente (SAC) - Segunda à Sexta: 9h às
                18h | Horário de Brasília
              </p>
              <a
                href="https://www.sixcomercio.com.br/suporte"
                className="inline-block bg-green-600 text-white font-bold py-3 px-6 rounded-full transition duration-300 hover:bg-green-700">
                Atendimento e Suporte
              </a>
            </div>
          </div>
          <div className="w-full lg:w-1/2 px-4">
            <div>
              <h2 className="text-3xl font-bold mb-6">Precisa de Ajuda?</h2>
              <p className="text-gray-300 mb-6">
                Fale conosco através do nosso email:
                <br />
                <a
                  href={`mailto:${data.emailContato}`}
                  className="text-white underline">
                  {data.emailContato}
                </a>
              </p>
              <h2 className="text-3xl font-bold mb-6">Encontre seu pedido</h2>
              <a
                href="https://cademeupedido.log.br/?t=U2l4UHJvZHV0b3M"
                className="inline-block bg-yellow-600 text-white font-bold py-3 px-6 rounded-full mb-6 transition duration-300 hover:bg-yellow-700">
                Rastrear seu Pedido
              </a>
              <p className="mb-6">{data.descricaoPrazo}</p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default AtendimentoComercial;
