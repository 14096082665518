import Timer from './Timer/Timer';
import Produtos from './Produtos/Produtos';
import Garantia from './Garantia/Garantia';
import FAQ from './FAQ/FAQ';

const Main = ({ data }: any) => {
  return (
    <div>
      <Produtos data={data} />
      <Timer isPvBg={true} />
      <Garantia data={data} />
      <FAQ data={data} />
    </div>
  );
};

export default Main;
