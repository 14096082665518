import React from 'react';
import Image from 'next/image';
import { Container } from '@/app/container/Container';

interface GarantiaProps {
  data: {
    garantia: {
      foto: string;
      titulo: string;
      descricao: string;
    };
  };
}

const Garantia: React.FC<GarantiaProps> = ({ data }) => {
  return (
    <section
      id="garantia"
      className="bg-white py-8 md:py-12"
      aria-labelledby="garantia-titulo">
      <Container>
        <div className="flex flex-col-reverse md:flex-row items-center justify-center gap-8">
          <div className="w-full flex justify-center">
            <Image
              src={data.garantia.foto}
              alt={data.garantia.titulo}
              width={300}
              height={300}
              className="object-cover object-center"
              unoptimized
            />
          </div>
          <div className="flex flex-col justify-center">
            <h2
              id="garantia-titulo"
              className="text-2xl md:text-3xl font-bold text-center md:text-left mb-4">
              {data.garantia.titulo}
            </h2>
            <p className="text-gray-700 text-center md:text-start mb-6 p-2">
              {data.garantia.descricao}
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Garantia;
