import { Container } from '@/app/container/Container';
import Image from 'next/image';
import Link from 'next/link';

export default function Seguranca() {
  return (
    <section id="seguranca" className="py-8 bg-gray-100">
      <Container>
        <div className="text-center mb-8">
          <h2 className="text-4xl font-bold uppercase">
            É a sua primeira compra na Internet?
          </h2>
          <h3 className="text-base mt-2">
            Não se preocupe, site{' '}
            <span className="font-medium text-green-600">100% seguro</span>,
            empresa confiável e entrega garantida!
          </h3>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 text-center">
          {[
            {
              src: '/backredirect/sigilo.png',
              alt: 'Entrega Rápida e Embalagem Discreta',
              text: 'Seus potes serão enviados com total sigilo, cuidado e segurança, desde a embalagem do produto até a entrega.'
            },
            {
              src: '/backredirect/compra.png',
              alt: 'Compra Segura e Entrega Garantida',
              text: 'Trabalhamos com a Payt, plataforma que utiliza os mais avançados recursos de segurança.'
            },
            {
              src: '/backredirect/parcela.png',
              alt: 'Parcelamento em até 12x',
              text: 'Facilitamos para sua compra, você poderá parcelar até 12x no cartão de crédito de sua preferência.'
            },
            {
              src: '/backredirect/atendimento.png',
              alt: 'Atendimento Premium',
              text: 'Nós temos um atendimento premium para todos os nossos clientes. No rodapé deste site tem todos os canais de atendimento, 0800 gratuito, Whatsapp e Email para que você tire todas as suas dúvidas e consiga ter a melhor experiência possível.'
            }
          ].map(({ src, alt, text }) => (
            <div key={src} className="flex flex-col items-center gap-4">
              <Image
                src={src}
                alt={alt}
                width={150}
                height={150}
                loading="lazy"
                unoptimized
              />
              <p className="text-xs">{text}</p>
            </div>
          ))}
        </div>
        <div className="text-center mt-8">
          <Link
            href="#tabelas"
            className="inline-block bg-green-500 text-white font-bold py-3 px-8 rounded-xl transition-colors duration-300 hover:bg-green-600 uppercase animate-scale tracking-widest">
            SIM, QUERO EXPERIMENTAR!
          </Link>
          <div className="mt-8">
            <Image
              src="/backredirect/selos-de-compra.png"
              alt="Selos de compra"
              width={300}
              height={100}
              loading="lazy"
              
            />
          </div>
        </div>
      </Container>
    </section>
  );
}
