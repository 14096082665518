// GradientBackground.tsx
import React, { ReactNode } from 'react';
import { lightenColor } from './colorUtils';

interface GradientBackgroundProps {
  primaryColor: string;
  lightenPercentage: number;
  children: ReactNode;
}

const GradientBackground: React.FC<GradientBackgroundProps> = ({
  primaryColor,
  lightenPercentage,
  children
}) => {
  const lighterColor = lightenColor(primaryColor, lightenPercentage);

  return (
    <div
      style={{
        background: `linear-gradient(to bottom, ${primaryColor}, ${lighterColor})`
      }}>
      {children}
    </div>
  );
};

export default GradientBackground;
