import Beneficios from './Beneficios/Beneficios';
import Formula from './Formula/Formula';
import Timer from './Timer/Timer';
import Produtos from './Produtos/Produtos';
import Duvidas from './Duvidas/Duvidas';
import Seguranca from './Seguranca/Seguranca';
import Garantia from './Garantia/Garantia';
import FAQ from './FAQ/FAQ';
import AtendimentoComercial from './AtendimentoComercial/AtendimentoComercial';

const Main = ({ data }: any) => {
  return (
    <div>
      <Beneficios data={data} />
      <Formula data={data} />
      <Timer />
      <Produtos data={data} />
      <Duvidas data={data} />
      <Seguranca />
      <Timer isPvBg={true} />
      <Garantia data={data} />
      <FAQ data={data} />
      <AtendimentoComercial data={data} />
    </div>
  );
};

export default Main;
