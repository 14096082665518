import { Container } from '@/app/container/Container';
import { ParseHtml } from '@/app/libs/parseHtml';

interface RodapeProps {
  data?: any;
}

export default function Rodape({ data }: RodapeProps) {
  return (
    <footer className="bg-gradient-to-b from-stone-700 to-stone-900 py-12 text-center text-white">
      <Container>
        <div className="mb-4">
          <h1 className="text-sm">
            <span dangerouslySetInnerHTML={ParseHtml(data?.titulo)}></span>
          </h1>
        </div>
        <div className="mb-2">
          <h2 className="text-sm">
            <span dangerouslySetInnerHTML={ParseHtml(data?.subTitulo)}></span>
          </h2>
          <p className="text-xs">
            <span dangerouslySetInnerHTML={ParseHtml(data?.conteudo)}></span>
          </p>
        </div>
      </Container>
    </footer>
  );
}
