import Image from 'next/image';
import { ParseHtml } from '@/app/libs/parseHtml';
import { Container } from '@/app/container/Container';

interface GarantiaProps {
  data?: any;
}

const Garantia = ({ data }: GarantiaProps) => {
  return (
    <section className="bg-gray-100 py-8">
      <Container>
        <div className="flex flex-col md:flex-row justify-center items-center">
          <div className="md:w-2/3 text-center mb-8 md:mb-0">
            <h2 className="text-4xl font-extrabold mb-4">
              <span dangerouslySetInnerHTML={ParseHtml(data?.titulo)}></span>
            </h2>
            <h3 className="text-2xl font-bold mb-4">
              <span dangerouslySetInnerHTML={ParseHtml(data?.subTitulo)}></span>
            </h3>
            <p className="text-sm">
              <span dangerouslySetInnerHTML={ParseHtml(data?.conteudo)}></span>
            </p>
          </div>
          <div className="md:w-1/3 text-center">
            <Image
              src={data?.imagem}
              alt="Garantia"
              width={300}
              height={300}
              className="mx-auto"
              unoptimized
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Garantia;
