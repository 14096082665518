const AvisoDesconto = ({ data }: any) => {
  return (
    <div className="flex justify-center text-center font-bold bg-black p-2">
      <p className="text-white animate-scale w-4/5 text-xs sm:text-base lg:text-lg xl:text-xl">
        {data?.textoDesconto ||
          '¡FELICIDADES! ¡ACABAS DE RECIBIR UN 50% DE DESCUENTO EN TODO EL SITIO!'}
      </p>
    </div>
  );
};

export default AvisoDesconto;
