import { Container } from '../../../../container/Container';
import Image from 'next/image';
import Link from 'next/link';

interface PrimeiraCompraProps {
  data: any;
  temaColors?: any;
}

const PrimeiraCompra = ({ data, temaColors }: PrimeiraCompraProps) => {
  const btnExperimentar = temaColors.btnExperimentar;
  const bgColor = temaColors.bgHeader2;

  const scrollToTabelas = () => {
    setTimeout(() => {
      const tabelasSection = document.getElementById('tabelas-section');
      if (tabelasSection) {
        tabelasSection.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  return (
    <div style={{ backgroundColor: bgColor }} className="py-8">
      <Container>
        <div className="text-center">
          <h2 className="text-2xl sm:text-4xl uppercase font-bold mb-4">
            {data.titulo}
          </h2>
          <p
            className={`text-[${temaColors['--font_primary']}] text-base mb-4`}>
            {data.subTitulo}
          </p>

          <ul className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-8">
            <li className="rounded-lg p-2 flex flex-col items-center">
              <Image
                src={`/backredirect${data.sigilo}`}
                alt="Entrega Rápida"
                width={100}
                height={100}
                className="w-full mb-2"
                unoptimized
              />
              <p
                className={`text-[${temaColors['--font_primary']}] text-center`}>
                {data.sigiloTexto}
              </p>
            </li>

            <li className="rounded-lg p-2 flex flex-col items-center">
              <Image
                src={`/backredirect${data.compra}`}
                alt="Compra Segura"
                width={100}
                height={100}
                className="w-full mb-2"
                unoptimized
              />
              <p
                className={`text-[${temaColors['--font_primary']}] text-center`}>
                {data.compraTexto}
              </p>
            </li>

            <li className="rounded-lg p-2 flex flex-col items-center">
              <Image
                src={`/backredirect${data.atendimento}`}
                alt="Servicio Premium"
                width={100}
                height={100}
                className="mb-2"
                unoptimized
              />
              <p
                className={`text-[${temaColors['--font_primary']}] text-center`}>
                {data.atendimentoTexto}
              </p>
            </li>
          </ul>

          <Link href="#tabelas-section">
            <button
              style={{ backgroundColor: btnExperimentar }}
              className="text-xl text-white py-4 px-8 rounded-md transition-colors duration-300 mx-auto animate-scale hover:brightness-90"
              onClick={scrollToTabelas}>
              {data.botaoComprar}
            </button>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default PrimeiraCompra;
