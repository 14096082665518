'use client';
import { useEffect, useState, useRef } from 'react';
import BackredirectTemplate from '../templates/backredirect/template';
import BackredirectLatamTemplate from '../templates/backredirect-latam/BackredirectLatamTemplate';
import VSLLatamTemplate from '../templates/vsl-latam/vsl-latam-template';
import PvLatamTemplate from '../templates/pv-latam/PvLatamTemplate';
import VslBrasilTemplate from '../templates/vsl-brasil/vsl-brasil-template';
import PvBrasilTemplate from '../templates/pv-brasil/PvBrasilTemplate';

interface ProxyProps {
  template: string;
  tipo: string;
  url: any;
  apiUrl: any;
}

interface TemplateData {
  clarityId?: string;
  gtagId?: string;
  vslId?: string;
  tipo?: string;
  script?: string;
}

export default function Proxy({ template, tipo, url, apiUrl }: ProxyProps) {
  const [data, setData] = useState<TemplateData | null>(null);
  const urlRef = useRef(url);

  useEffect(() => {
    if (template === 'favicon.ico') {
      return;
    }

    let modifiedUrl = urlRef.current.replace(/^www\./, '');
    console.log('link sem o WWW:', modifiedUrl);

    if (template && tipo) {
      modifiedUrl += '/backredirect';
    } else if (template) {
      modifiedUrl += '/' + template;
    }

    console.log(`URL: ${apiUrl}/template?origin=${modifiedUrl}`);

    fetch(`${apiUrl}/template?origin=${modifiedUrl}`)
      .then((res) => res.json())
      .then((data) => {
        setData(data.object);
        console.log(data);
      })
      .catch((error) => {
        console.error('Erro ao buscar dados do template:', error);
      });

    // Capturar e armazenar os parâmetros UTM
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams: { [key: string]: string } = {};
    urlParams.forEach((value, key) => {
      if (key.startsWith('?')) {
        utmParams[key] = value;
      }
    });
    localStorage.setItem('utmParams', JSON.stringify(utmParams));
  }, [template, tipo, apiUrl]);

  useEffect(() => {
    if (data) {
      const clarityId = data.clarityId;
      const gtagId = data.gtagId;
      const vslId = data.vslId;
      const scriptContent = data.script;

      if (clarityId) {
        const clarityScript = document.createElement('script');
        clarityScript.type = 'text/javascript';
        clarityScript.innerHTML = `
          (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "${clarityId}");
        `;
        document.head.appendChild(clarityScript);
      }

      if (gtagId) {
        const gtagScript1 = document.createElement('script');
        gtagScript1.async = true;
        gtagScript1.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;
        document.head.appendChild(gtagScript1);

        const gtagScript2 = document.createElement('script');
        gtagScript2.type = 'text/javascript';
        gtagScript2.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${gtagId}');
        `;
        document.head.appendChild(gtagScript2);
      }

      if (vslId) {
        const preloadLinks = [
          `https://scripts.converteai.net/878e8e96-9e3c-48f1-96d9-9d726cef585a/players/${vslId}/player.js`,
          `https://cdn.converteai.net/lib/js/smartplayer/v1/smartplayer.min.js`,
          `https://images.converteai.net/878e8e96-9e3c-48f1-96d9-9d726cef585a/players/${vslId}/thumbnail.jpg`,
          `https://cdn.converteai.net/878e8e96-9e3c-48f1-96d9-9d726cef585a/${vslId}/main.m3u8`,
          `https://cdn.converteai.net`,
          `https://scripts.converteai.net`,
          `https://images.converteai.net`,
          `https://api.vturb.com.br`
        ];

        preloadLinks.forEach((link) => {
          const linkElement = document.createElement('link');
          linkElement.rel = 'preload';
          linkElement.href = link;
          linkElement.as = 'script';
          document.head.appendChild(linkElement);
        });
      }

      if (scriptContent && scriptContent.trim() !== '') {
        try {
          const redTrackScript = document.createElement('script');
          redTrackScript.type = 'text/javascript';
          redTrackScript.src = scriptContent;
          document.head.appendChild(redTrackScript);
        } catch (error) {
          console.error(
            'Erro ao adicionar o script de trackeamento do RedTrack:',
            error
          );
        }
      }
    }
  }, [data]);

  return (
    <>
      {data && (
        <>
          {(data as any).tipo === 'pv-brasil' && (
            <PvBrasilTemplate data={data} />
          )}

          {(data as any).tipo === 'vsl-brasil' && (
            <VslBrasilTemplate data={data} />
          )}

          {(data as any).tipo === 'backredirect' && (
            <BackredirectTemplate
              data={data}
              backredirect={template}
              tipo={tipo}
            />
          )}

          {(data as any).tipo === 'pv-latam' && (
            <PvLatamTemplate data={data} />
          )}

          {(data as any).tipo === 'vsl-latam' && (
            <VSLLatamTemplate data={data} />
          )}

          {(data as any).tipo === 'backredirect-latam' && (
            <BackredirectLatamTemplate
              data={data}
              backredirect={template}
              tipo={tipo}
            />
          )}
        </>
      )}
    </>
  );
}
