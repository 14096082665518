import BackToTopButton from './backToTopButton';
import { GoogleAnalytics } from '@next/third-parties/google';
import Header from './components/Header/Header';
import AvisoDesconto from './components/Header/AvisoDesconto';
import Hero from './components/Hero/Hero';
import Main from './components/Main/Main';
import Footer from './components/Footer/Footer';
import BackIntentScript from '../BackIntentScript';

export default function PvLatamTemplate({ data }: any) {
  console.log(data);
  return (
    <>
      <GoogleAnalytics gaId={data?.gtagId} />
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "${data?.clarityId}");
          `
        }}
      />
      <div id="pv-latam-template" style={data.tema}>
        <Header data={data?.header} />
        <AvisoDesconto data={data?.header} />
        <Hero data={data} />
        <Main data={data} />
        <Footer data={data} />
        <BackToTopButton />
        <BackIntentScript redirectUrl={data?.redirectUrl} tipo={data?.tipo} />
      </div>
    </>
  );
}
