import React, { useEffect, useState } from 'react';
import ProdutosData from './ProdutosData';

const Produtos = ({ data }: any) => {
  const [queryParams, setQueryParams] = useState<string>('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const searchParams = window.location.search;
      setQueryParams(
        searchParams.startsWith('?') ? searchParams.slice(1) : searchParams
      );
    }
  }, []);

  return (
    <section className="crm-primary-bg text-white py-12" id="produtos">
      <div className="container mx-auto px-4">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold">
            Escolha a Melhor Opção Para Você:
          </h2>
          <p className="text-lg mt-2">{data?.produto.descricao}</p>
        </div>
        <div className="flex flex-wrap justify-center">
          {data.produto.produtos.map((produto: any, index: number) => (
            <ProdutosData
              key={index}
              produto={produto}
              queryParams={queryParams}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Produtos;
