import { Container } from '@/app/container/Container';
import React from 'react';

interface FAQProps {
  data: {
    faq: {
      titulo: string;
      items: {
        pergunta: string;
        resposta: string;
      }[];
    };
  };
}

const FAQ = ({ data }: FAQProps) => {
  return (
    <section
      id="faq"
      className="crm-primary-bg py-12"
      aria-labelledby="faq-titulo">
      <Container>
        <div className="w-full">
          <div>
            <h2
              id="faq-titulo"
              className="text-3xl md:text-5xl font-bold text-center text-white mb-8">
              {data.faq.titulo}
            </h2>
            <div className="flex flex-col gap-4 p-2">
              {data.faq.items.map((item, index) => (
                <div
                  key={index}
                  className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box">
                  <input type="checkbox" className="peer" />
                  <div className="collapse-title text-sm md:text-lg font-medium bg-gray-100 text-black">
                    {item.pergunta}
                  </div>
                  <div className="collapse-content">
                    <p>{item.resposta}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default FAQ;
