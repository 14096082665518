import Tabelas from './components/tabelas';
import Header from './components/Header/Header';
import { Container } from '../../container/Container';
import GradientBackground from './GradientBackground';
import PrimeiraCompra from './components/PrimeiraCompra/PrimeiraCompra';
import GarantiaBlindada from './components/GarantiaBlindada/GarantiaBlindada';
import EntregaSegura from './components/EntregaSegura/EntregaSegura';
import Footer from '../vsl-latam/components/footer/Footer';
import BackIntentScript from '../BackIntentScript';
interface BackredirectColombiaTemplateProps {
  data: any;
  backredirect: string;
  tipo: string;
}

const BackredirectColombiaTemplate = ({
  data,
  backredirect,
  tipo
}: BackredirectColombiaTemplateProps) => {
  const tabelas = data.tabelas[backredirect];
  const bgHeader1 = data?.tema['--bg_header1'];
  console.log(data?.tabelas);

  const temaColors = {
    primary: data.tema['--primary'],
    secondary: data.tema['--secondary'],
    btnExperimentar: data.tema['--btn_experimentar'],
    bgHeader1: data.tema['--bg_header1'],
    bgHeader2: data.tema['--bg_header2'],
    produtoInfoSmall: data.tema['--produto_info_small'],
    precoHeader: data.tema['--preco_header'],
    vermelho: data.tema['--vermelho'],
    tabelaDestaque: data.tema['--tabela_destaque']
  };

  return (
    <>
      <GradientBackground primaryColor={bgHeader1} lightenPercentage={30}>
        <Container>
          <Header data={data?.tabelas} />
          <Tabelas
            data={tabelas}
            tipo={tipo}
            temaColors={temaColors}
            id="tabelas-section"
          />
        </Container>
      </GradientBackground>
      <PrimeiraCompra data={data.primeiraCompra} temaColors={temaColors} />
      <GarantiaBlindada data={data.garantia} temaColors={temaColors} />
      <EntregaSegura data={data.entrega} />
      <Footer data={data} />
      <BackIntentScript redirectUrl={data?.redirectUrl} tipo={data?.tipo} />
    </>
  );
};
export default BackredirectColombiaTemplate;
