import React from 'react';
import Image from 'next/image';
import { Container } from '@/app/container/Container';

interface GarantiaProps {
  data: {
    garantia: {
      foto: string;
      titulo: string;
      descricao: string;
      botao: string;
    };
  };
}

const Garantia: React.FC<GarantiaProps> = ({ data }) => {
  return (
    <section
      id="garantia"
      className="bg-white py-8 md:py-12"
      aria-labelledby="garantia-titulo">
      <Container>
        <div className="flex flex-col items-center text-center">
          <h2
            id="garantia-titulo"
            className="text-3xl md:text-4xl font-bold text-center mb-4 crm-primary-text brightness-75">
            {data.garantia.titulo}
          </h2>
          <p className="text-gray-700 text-center mb-6 p-2">
            {data.garantia.descricao}
          </p>
          <div className="w-full flex justify-center mb-6">
            <Image
              src="/garantia.webp"
              alt={data.garantia.titulo}
              width={150}
              height={150}
              className="w-full h-full"
            />
          </div>
          <a
            href="#"
            className="inline-block bg-black/75 text-sm md:text-base text-white font-bold py-3 px-6 rounded-full transition-colors duration-300 hover:brightness-110 hover:shadow-lg">
            {data.garantia.botao}
          </a>
        </div>
      </Container>
    </section>
  );
};

export default Garantia;
