// 'use client' é uma diretiva que indica que o script deve ser executado apenas no lado do cliente (navegador).
'use client';

// Importação de bibliotecas e componentes necessários
import React, { useState, useRef, useEffect } from 'react';
import AvisoDesconto from './components/header/AvisoDesconto';
import Header from './components/header/Header';
import VSL from './components/vsl/VSL';
import FacebookComments from './components/FacebookComments/FacebookComments';
import ReferenciaCientifica from './components/ReferenciaCientifica/ReferenciaCientifica';
import Main from './components/main/Main';
import Footer from './components/footer/Footer';
import BackIntentScript from '../BackIntentScript'; // Importação do BackIntentScript

// Componente principal do template
export default function VSLLatamTemplate({ data }: any) {
  // Estado para controlar a exibição do componente Main
  const [showMain, setShowMain] = useState(false);
  // Estado para controlar a exibição do componente AvisoDesconto
  const [showAvisoDesconto, setShowAvisoDesconto] = useState(false);
  // Referência para o elemento de vídeo
  const videoRef = useRef<HTMLIFrameElement>(null);
  // Referência para o contêiner do produto
  const productContainerRef = useRef<HTMLDivElement | null>(null);
  const pitch = data.pitch;

  // Função para exibir os elementos Main e AvisoDesconto
  const showEls = () => {
    setShowMain(true);
    setShowAvisoDesconto(true);
  };

  // Expondo a função showEls para ser chamada no console do navegador
  if (typeof window !== 'undefined') {
    window.showEls = showEls;
  }

  // Efeito para controlar a exibição dos elementos após o pitch
  useEffect(() => {
    const storedPitchStatus =
      typeof window !== 'undefined'
        ? localStorage.getItem('pitchStatus')
        : 'false';
    const timerExpired = storedPitchStatus === 'true';

    if (timerExpired) {
      // Se o pitch já foi exibido, exibe os elementos imediatamente
      setShowMain(true);
    } else {
      // Caso contrário, define um timer para exibir os elementos após o pitch
      const timer = setTimeout(() => {
        setShowMain(true);
        setShowAvisoDesconto(true);
        setShowAvisoDesconto(true);
        // Marca o pitch como exibido
        if (typeof window !== 'undefined') {
          localStorage.setItem('pitchStatus', 'true');
        }
        // Após o pitch, rola a página para o contêiner do produto
        setTimeout(() => {
          const containerRef = productContainerRef.current;
          if (containerRef) {
            const yOffset =
              containerRef.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: yOffset, behavior: 'smooth' });
          }
        }, 1000);
      }, pitch * 1000);

      // Limpa o timer quando o componente for desmontado
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <div style={data?.tema}>
      {/* Exibe o AvisoDesconto se showAvisoDesconto for true */}
      {showAvisoDesconto && <AvisoDesconto data={data} />}
      <Header data={data.header} />
      <VSL data={data.header} videoRef={videoRef} />
      {/* Exibe o FacebookComments se o Main ainda não tiver sido exibido */}
      {!showMain && <FacebookComments data={data.UserCommentsFb} />}
      {/* Exibe o ReferenciaCientifica se o Main ainda não tiver sido exibido */}
      {!showMain && <ReferenciaCientifica data={data.ReferenciaCientifica} />}
      {/* Exibe o Main se showMain for true */}
      {showMain && (
        <div ref={productContainerRef}>
          <Main data={data} />
        </div>
      )}
      {/* Exibe o Footer */}
      <Footer data={data} />
      <BackIntentScript redirectUrl={data?.redirectUrl} tipo={data.tipo} />
    </div>
  );
}
