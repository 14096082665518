import { Container } from '@/app/container/Container';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';

interface TabelasProps {
  data?: any;
  tipo: string;
}

export default function Tabelas({ data, tipo }: TabelasProps) {
  const [queryParams, setQueryParams] = useState<string>('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const searchParams = window.location.search;
      setQueryParams(
        searchParams.startsWith('?') ? searchParams.slice(1) : searchParams
      );
    }
  }, []);

  const getCheckoutUrlWithParams = (checkoutUrl: string) => {
    const url = new URL(checkoutUrl);
    if (queryParams) {
      const existingParams = url.searchParams.toString();
      url.search = existingParams
        ? `${existingParams}&${queryParams}`
        : `?${queryParams}`;
    }
    return url.toString();
  };

  console.log('tabelas', data);

  return (
    <section id="tabelas" className="py-12 crm-primary-bg">
      <Container>
        <header className="flex justify-center items-center pb-12 text-center">
          <h1 className="text-4xl font-bold text-white">{data.titulo}</h1>
        </header>
        <div className="flex flex-col lg:flex-row justify-center items-center gap-8">
          {data?.produtos.map((produto: any, index: number) => (
            <article
              className={`w-full transform transition-transform ${
                index === 1 ? 'scale-105 md:scale-110' : 'scale-100'
              }`}
              key={index}>
              <Link href={getCheckoutUrlWithParams(produto.checkouts[tipo])}>
                <div className="bg-black rounded-lg shadow-lg overflow-hidden">
                  <div className="flex bg-gray-950 relative gap-2 text-center items-center justify-center p-2">
                    <div className="flex flex-col justify-center items-center absolute top-0 left-0 bg-yellow-500 text-black p-2 font-bold">
                      <p className="text-lg">50%</p>
                      <p className="text-xs">OFF</p>
                    </div>
                    <div className="flex flex-col justify-center items-center text-base text-white">
                      <p className="text-yellow-500 font-bold text-lg">
                        {produto.produtoInfo}
                      </p>
                      <p className="text-xs">{produto.produtoTratamento}</p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 p-4 text-center">
                    <div className="mb-4">
                      <Image
                        src={produto.imagem}
                        alt={produto.produtoInfo}
                        width={300}
                        height={300}
                        className="w-1/2 lg:w-4/5 mx-auto hover:scale-105 transform transition-transform duration-300 ease-in-out"
                        unoptimized
                      />
                    </div>

                    <div className="flex flex-col p-2 gap-2">
                      <div className="flex justify-between text-white text-sm">
                        <p>{produto.precoTabela}</p>
                        <span className="text-red-500 line-through">
                          {produto.precoAntigo}
                        </span>
                      </div>
                      <div className="flex justify-between text-white text-sm">
                        <p>{produto.legendaPrecoComDesconto}</p>
                        <span className="text-sm">{produto.precoDesconto}</span>
                      </div>
                      <div className="flex justify-between items-center text-white text-sm">
                        <p>{produto.legendaParcela}</p>
                        <div className="flex items-end text-yellow-500 font-bold">
                          <span className="text-base">R$</span>{' '}
                          <span className="text-4xl">
                            {produto.precoDescontoParcela.split(' ')[1]}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <p className="text-yellow-500 font-bold">Frete Gratis</p>
                    </div>
                    <div>
                      <button className="text-lg bg-yellow-500 text-black font-bold py-2 px-4 rounded-lg uppercase">
                        {produto.botaoComprar}
                      </button>
                    </div>
                  </div>
                </div>
              </Link>
            </article>
          ))}
        </div>
      </Container>
    </section>
  );
}
