import { FC } from 'react';
import { Container } from '@/app/container/Container';
import Image from 'next/image';
import Link from 'next/link';

interface FooterProps {
  data: {
    copyright: string;
  };
}

const Footer: FC<FooterProps> = ({ data }) => {
  return (
    <footer className="bg-black/90 text-white py-8" id="sec-1c68">
      <Container>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
          <div>
            <h3 className="text-xl font-bold mb-4">Site Seguro</h3>
            <div className="flex items-center justify-center">
              <Image
                src="/pv-brasil/siteseguro-_1_.webp"
                alt="Google Site Seguro"
                width={150}
                height={50}
                className="object-contain"
              />
            </div>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4">Métodos de Entrega</h3>
            <div className="flex justify-center">
              <Image
                src="/pv-brasil/siteseguro2.png"
                alt="Métodos de Entrega (Sedex)"
                width={150}
                height={50}
                className="object-contain"
              />
            </div>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4">Formas de Pagamento</h3>
            <div className="flex justify-center">
              <Image
                src="/pv-brasil/Design-sem-nome-_3_.webp"
                alt="Formas de Pagamento"
                width={150}
                height={50}
                className="object-contain"
              />
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-700 pt-4 text-center">
          <div className="w-full grid grid-cols-2 gap-2 text-center text-sm md:text-base crm-primary-text brightness-200">
            <div className="flex justify-center">
              <Link
                href="https://sevencomercio.com/politicas/assinatura/"
                className="w-full hover:underline">
                Política de Assinatura
              </Link>
            </div>
            <Link
              href="https://sevencomercio.com/politicas/privacidade/"
              className="hover:underline">
              Política de Privacidade
            </Link>
            <Link
              href="https://sevencomercio.com/politicas/devolucao/"
              className="hover:underline">
              Política de Troca e Reembolso
            </Link>
            <Link
              href="https://sevencomercio.com/politicas/termos/"
              className="hover:underline">
              Termos e Condições de Uso
            </Link>
          </div>
          <p className="mt-4 text-gray-400 text-sm md:text-base">
            {data.copyright}
          </p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
