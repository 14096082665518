import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

interface ProdutoProps {
  produto: any;
  queryParams: string;
}

const ProdutosData: React.FC<ProdutoProps> = ({ produto, queryParams }) => {
  const getCheckoutUrlWithParams = (checkoutUrl: string) => {
    const url = new URL(checkoutUrl);
    if (queryParams) {
      const existingParams = url.searchParams.toString();
      url.search = existingParams
        ? `${existingParams}&${queryParams}`
        : `?${queryParams}`;
    }
    return url.toString();
  };

  return (
    <div className="flex justify-center items-center bg-black rounded-lg shadow-lg p-6 m-4">
      <div className="flex flex-col items-center justify-center gap-2 text-center">
        <a
          href={getCheckoutUrlWithParams(produto.checkout)}
          className="w-full block bg-yellow-400 text-black font-bold py-2 px-4 rounded-full">
          50% de desconto
        </a>
        <h5 className="text-lg font-medium">
          <span>{produto.titulo}</span>
          <br />
          <span className="font-medium">{produto.subTitulo}</span>
        </h5>
        <Image
          className="mx-auto"
          width={300}
          height={300}
          src={produto.foto}
          alt={produto.titulo}
          unoptimized
        />
        <h5 className="text-lg text-white font-medium">
          DE {produto.valorDe} POR APENAS <br /> {produto.valorPor} OU 12X DE
        </h5>
        <h5 className="text-6xl text-yellow-400 font-bold">
          {produto.valorParcelado}
        </h5>
        <h5 className="text-lg text-white">
          DESCONTO DE{' '}
          <span className="text-green-400 font-medium">{produto.desconto}</span>
        </h5>
        <h5 className="text-lg text-yellow-400 font-medium">
          {produto.valorFrete}
        </h5>
        <Link
          href={getCheckoutUrlWithParams(produto.checkout)}
          className="block bg-yellow-400 text-black font-bold py-2 px-4 rounded-full">
          COMPRAR AGORA!
        </Link>
      </div>
    </div>
  );
};

export default ProdutosData;
