import Link from 'next/link';
import { Container } from '../../../../container/Container';
import { useState, useEffect } from 'react';
import Image from 'next/image';
import whatsappIcon from '/public/whatsapp.svg'; // Certifique-se de que o caminho para o seu SVG está correto

const Footer = ({ data, showAfterPitch }: any) => {
  const [footerData, setFooterData] = useState(data.footer);

  useEffect(() => {
    console.log('showAfterPitch:', showAfterPitch);
    console.log('data.footerAfter:', data.footerAfter);

    if (showAfterPitch && data.footerAfter) {
      setFooterData((prevData: any) => ({
        ...prevData,
        links: [
          { name: 'Compre via WhatsApp', href: data.footerAfter.linkWhats },
          ...(prevData.links || []) // Garantir que prevData.links seja um array
        ],
        televendas: data.footerAfter.televendas
      }));
    }
  }, [showAfterPitch, data.footerAfter]);

  useEffect(() => {
    console.log('footerData updated:', footerData);
  }, [footerData]);

  return (
    <footer className="bg-black text-white text-center py-8 text-sm">
      <Container>
        <div className="flex flex-col md:flex-row justify-between gap-4">
          <div className="flex flex-col md:text-left gap-4 w-full">
            <p className="text-stone-500">{footerData?.direitosReservados}</p>
            <div>
              <h3 className="text-sm font-bold mb-2">Links úteis</h3>
              <ul className="flex flex-col gap-2 text-blue-500">
                {showAfterPitch && data.footerAfter && (
                  <li>
                    <Link href={data.footerAfter.linkWhats}>
                      Compre via WhatsApp
                    </Link>
                  </li>
                )}
                <li>
                  <Link href="https://sevencomercio.com/politicas/termos/">
                    Termos de uso
                  </Link>
                </li>
                <li>
                  <Link href="#">Isenção de responsabilidade</Link>
                </li>
                <li>
                  <Link href="https://sevencomercio.com/politicas/privacidade/">
                    Políticas de Privacidade
                  </Link>
                </li>
                <li>
                  <Link href="https://sevencomercio.com/politicas/devolucao/">
                    Política de reembolso
                  </Link>
                </li>
                <li>
                  <Link href="#">Políticas de envio</Link>
                </li>
                <li>
                  <Link href="#">Reportar Spam</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col gap-4 text-start max-w-3xl text-xs">
            <p className="border border-white p-2">{footerData?.textoAnvisa}</p>
            <p>{footerData?.descricao}</p>
            {showAfterPitch && data.footerAfter && (
              <>
                <div className="flex flex-col items-center md:items-start justify-center gap-4">
                  <p>
                    Clique no botão para ser redirecionado para nossa central de
                    vendas:
                  </p>
                  <Link
                    href={data.footerAfter.linkWhats}
                    className="p-2 bg-green-500 rounded-md max-w-fit text-white flex items-center gap-2 hover:bg-green-600 transition-all duration-300 font-medium">
                    <Image
                      src={whatsappIcon}
                      alt="WhatsApp"
                      width={24}
                      height={24}
                      unoptimized
                    />
                    QUERO COMPRAR VIA WHATSAPP
                  </Link>
                  <p>{footerData?.televendas}</p>
                </div>
              </>
            )}
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;