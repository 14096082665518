import React from 'react';
import ProdutosData from './ProdutosData';
import { Container } from '@/app/container/Container';

interface Produto {
  id: string;
  title: string;
  tempoTratamento: string;
  imagem: string;
  semDesconto: string;
  preco: number;
  link: string;
}

interface ProdutosProps {
  data: {
    produtos: Produto[];
  };
}

const Produtos = ({ data }: ProdutosProps) => {
  return (
    <section className="bg-white text-black py-12" id="sec-7b55">
      <Container>
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-6 items-center justify-center">
          {data.produtos.map((produto, index) => (
            <ProdutosData key={index} produto={produto} />
          ))}
        </div>
      </Container>
    </section>
  );
};

export default Produtos;