import { Container } from '@/app/container/Container';
import { ParseHtml } from '@/app/libs/parseHtml';
import Image from 'next/image';

interface HeaderProps {
  data: any;
}

function highlightNao(text: string) {
  return text.replace(
    /não/gi,
    '<span class="text-red-600 font-bold">NÃO</span>'
  );
}

export default function Header({ data }: HeaderProps) {
  return (
    <header id="header" className="w-full py-12 crm-primary-bg">
      <Container>
        <a href="#tabelas">
          <div className="w-11/12 md:w-full mx-auto flex flex-col items-center shadow-lg animate-scale border-dashed border-2 border-black">
            <div className="w-full bg-red-600 text-white text-xl md:text-2xl text-center p-2 md:p-4 font-bold">
              {data?.boletoLiberado}
            </div>
            <div className="w-full flex flex-col justify-center items-center text-center gap-2 py-2 md:py-4 bg-yellow-100">
              <p className="text-lg">{data?.voceFoiContempladoCom}</p>
              <p className="text-xl md:text-2xl font-bold">{data?.desconto} </p>

              <p className="text-sm">
                <span
                  dangerouslySetInnerHTML={ParseHtml(
                    data?.descontoTempo
                  )}></span>
              </p>
            </div>
            <div className="bg-gray-100">
              <div className="flex gap-4 items-center justify-between pr-4 text-sm md:text-base">
                <Image
                  src={data?.cupomImagem}
                  className="w-1/2 md:w-full"
                  alt=""
                  width={300}
                  height={300}
                  unoptimized
                />

                <span
                  className="w-1/2 md:w-full"
                  dangerouslySetInnerHTML={{
                    __html: highlightNao(data?.cupom)
                  }}></span>
              </div>
            </div>
          </div>
        </a>
      </Container>
    </header>
  );
}
