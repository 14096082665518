import { Container } from '@/app/container/Container';

const Beneficios = ({ data }: any) => {
  return (
    <section className="crm-primary-bg" id="carousel_aeb6">
      <Container>
        <div className="text-center p-10">
          <h2 className="text-4xl font-bold text-white">
            {data.beneficios.titulo}
          </h2>
          <p className="text-xl text-white mt-2 mb-4">
            {data.beneficios.descricao}
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 justify-items-center">
            {data.beneficios.fotos.map((item: any, index: number) => (
              <div key={index} className="flex flex-col items-center">
                <div
                  className="w-44 h-44 bg-cover bg-center rounded-full shadow-lg hover:shadow-xl transition-transform duration-300 hover:scale-105"
                  style={{ backgroundImage: `url(${item.foto})` }}
                  tabIndex={0}
                  aria-label={`Imagem mostra o seguinte benefício: ${item.descricao}`}
                  role="img"></div>
                <div
                  className="text-center text-white mt-2"
                  aria-label={item.descricao}>
                  {item.descricao}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Beneficios;
