import React, { useEffect, useState } from 'react';
import { Container } from '../../../../container/Container';

interface HeaderProps {
  data: {
    mensagemDemanda: string;
    titulo: string;
  };
}

const Header: React.FC<HeaderProps> = ({ data }) => {
  const [randomNumber, setRandomNumber] = useState<number>(
    randomInRange(500, 1500)
  );

  useEffect(() => {
    const interval = setInterval(
      () => {
        setRandomNumber(randomInRange(500, 1500));
      },
      3 * 60 * 1000
    );

    return () => clearInterval(interval);
  }, []);

  const formatDate = (): string => {
    const date = new Date();
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  return (
    <header className="bg-white">
      <Container>
        {/* pré-headline */}
        <p className="text-sm font-semibold pt-6 text-center">
          <span className="font-bold text-red-500">{randomNumber} </span>
          {data?.mensagemDemanda}
          <span className="font-bold text-red-500"> {formatDate()}</span>
        </p>
        {/* headline */}
        <h1 className="text-xl font-bold text-center mb-4 my-4 md:text-2xl lg:text-3xl">
          {data?.titulo}
        </h1>
      </Container>
    </header>
  );
};

export default Header;

// Função para gerar um número aleatório dentro de um intervalo específico
function randomInRange(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
