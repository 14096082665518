import React from 'react';
import Timer from './Timer';

const Header = ({ data }: any) => {
  console.log(data);

  return (
    <div className="flex flex-col gap-6 sm:gap-10 justify-center items-center mt-6 sm:mt-10 text-center text-white font-bold text-2xl sm:text-5xl text-transform: uppercase">
      <h1 style={{ textShadow: '0px 1px 6px rgba(0, 0, 0)' }}>{data.titulo}</h1>
      <Timer />
    </div>
  );
};

export default Header;
