import Image from 'next/image';
import style from './Testemunhas.module.css';

// Interface para definir a estrutura dos dados de uma testemunha
interface TestimonyData {
  nome: string;
  local: string;
  frase: string;
  comentario: string;
  foto: string;
}

// Interface para definir a estrutura das props do componente Testemunhas
interface TestimonyProps {
  data: TestimonyData[];
}

// Função de renderização para uma única testemunha
const RenderTestimony = ({
  nome,
  local,
  frase,
  comentario,
  foto
}: TestimonyData) => (
  <div
    className={`${style.testBorder} flex flex-col md:flex-row items-center md:items-start gap-10 mx-auto p-10 bg-white border-2`}>
    {/* Div para conter o texto da testemunha */}
    <div className="flex flex-col items-center md:items-start gap-6">
      <p className="text-xs text-justify">
        {nome}, {local}
      </p>
      <h2 className="font-bold italic text-3xl sm:text-4xl">
        &#34;{frase}&#34;
      </h2>
      <p className="text-sm text-justify">{comentario}</p>
    </div>
    {/* Div para conter a imagem da testemunha e a avaliação */}
    <div className="flex flex-col items-center text-center gap-3 max-w-max">
      <Image
        src={foto}
        alt="Testemunha"
        width={120}
        height={120}
        loading="lazy"
        unoptimized
      />
      <Image
        src={'/avaliacao.png'}
        alt="Avaliação"
        width={60}
        height={60}
        loading="lazy"
      />
      <p>{nome}</p>
    </div>
  </div>
);

// Componente principal Testemunhas
const Testemunhas = ({ data }: TestimonyProps) => {
  return (
    // Seção para conter todas as testemunhas
    <section
      aria-label="Testemunhas"
      className="flex flex-col items-center gap-10">
      {/* Mapeamento sobre os dados das testemunhas para renderizá-las */}
      {data?.map((testimony, index) => (
        <RenderTestimony
          key={index} // Chave única para cada elemento renderizado
          nome={testimony.nome}
          local={testimony.local}
          frase={testimony.frase}
          comentario={testimony.comentario}
          foto={testimony.foto}
        />
      ))}
    </section>
  );
};

export default Testemunhas;
