import React from 'react';
import Link from 'next/link';

const NavBar = () => {
  return (
    <div>
      <nav>
        <ul className="flex items-center gap-4 text-white text-sm uppercase">
          <li>
            <Link
              href="#produtos"
              className="hover:text-gray-300 focus:text-gray-300 focus:outline-none transition-colors duration-200">
              Experimentar Agora
            </Link>
          </li>
          <li>
            <Link
              href="#faq"
              className="hover:text-gray-300 focus:text-gray-300 focus:outline-none transition-colors duration-200">
              Perguntas Frequentes
            </Link>
          </li>
          <li>
            <Link
              href="#garantia"
              className="hover:text-gray-300 focus:text-gray-300 focus:outline-none transition-colors duration-200">
              Garantia
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default NavBar;
