import Image from 'next/image';
import SideNavigation from '../../SideNavigation';
import NavBar from '../Navbar/NavBar';
import { Container } from '@/app/container/Container';
import Link from 'next/link';

const Header = ({ data }: any) => {
  return (
    <header className="crm-primary-bg py-4 md:py-6" id="sec-b4a2">
      <Container>
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center">
            <Link href="#" passHref>
              <Image
                alt="Logo"
                width={100}
                height={100}
                src={data.logo}
                unoptimized
              />
            </Link>
          </div>
          <div className="hidden sm:flex sm:justify-end sm:flex-grow">
            <NavBar />
          </div>
          <div className="sm:hidden">
            <SideNavigation />
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
